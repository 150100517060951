import { Stack, Typography, Box } from '@mui/material';
import { EditPenIcon, LocationIcon, ZoneIcon } from 'views/components/icon';
import './warehouses.scss';
import { Button, Table } from 'views/components/elements';
import { WarehouseTab } from './components/WarehouseTab';
import { WarehouseIcon } from 'views/components/icon';
import { useWarehouseStore } from 'store/warehouse';
import HeaderWithAction from 'views/components/elements/HeaderWithAction';
import { useEffect, useRef, useState } from 'react';
import { Location, Warehouse, Zone } from 'models';
import WarningCard from 'views/components/elements/WarningCard';
import WarehouseForm from 'views/pages/settings/forms/WarehouseForm';
import PopUpDialog from 'views/components/elements/PopUpDialog';
import CustomAccordion from 'views/components/elements/CustomAccordian';
import { Actions, BodyText, Content, TitleText } from '../delivery/StyledComponent';
import IOSSwitch from 'views/components/elements/CustomSwitch';
import { fetchZones, updateZoneStatus } from 'gateways/warehouse';
import { handleError } from 'views/shared/utils/error-utils';
import { EntityResponse } from 'views/shared/utils/delivery-utils';
import { ZoneForm } from 'views/pages/settings/forms';

type PopUpFormTypes = 'warehouse' | 'zone' | 'location';
type PopUpState = 'open' | 'close';
type ConfirmationDialogType = 'zone' | 'location';
type DialogConfigOptions = {
  isOpen?: boolean;
  message?: string;
  type?: ConfirmationDialogType;
  data?: Partial<Zone & Location>
}
const defaultDialogConfig: DialogConfigOptions = {
  isOpen: false,
  message: '',
  data: {},
};

const Warehouses: React.FC = () => {
  const { selectedWarehouse, fetchWarehouses, resetData } = useWarehouseStore();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const isInitialFetchCompleted = useRef(false);
  const [zones, setZones] = useState<Zone[]>([]);
  const [isZoneDataFetched, setIsZoneDataFetched] = useState(false);
  const [selectedZone, setSelectedZone] = useState<Zone>();

  useEffect(() => {
    if (!isInitialFetchCompleted.current) {
      fetchWarehouses().then(() => setIsDataFetched(true));
      isInitialFetchCompleted.current = true;
    }

    return () => resetData();
  }, []);

  const fetchZoneDetails = async (warehouse_id: number): Promise<EntityResponse<Zone[]>> => {
    const response = await fetchZones(warehouse_id);
    return handleError<Zone[]>(response);
  };

  const updateZone = async (zone: Partial<Zone>): Promise<EntityResponse<Zone>> => {
    const response = await updateZoneStatus(zone as Zone);
    return handleError<Zone>(response);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedWarehouse?.warehouse_id) {
        const { failed, data } = await fetchZoneDetails(selectedWarehouse.warehouse_id);
        setIsZoneDataFetched(true);

        if (!failed) {
          setZones(data);
        }
      }
    };

    fetchData();

    return () => {
      setZones([]);
      setIsZoneDataFetched(false);
    };
  }, [selectedWarehouse]);

  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditForm, setIsEditForm] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState<{
    warehouse?: boolean;
    zone?: boolean;
    location?: boolean;
  }>({});
  const [popUpDialogConfig, setPopUpDialogConfig] = useState<DialogConfigOptions>(defaultDialogConfig);

  const [ selectedForm, setSelectedForm ] = useState<PopUpFormTypes>('warehouse');

  const handlePopUp =
    (popUp: PopUpFormTypes, type: PopUpState, isEdit?: boolean) => () => {
      setIsEditForm(Boolean(isEdit));
      setSelectedForm(popUp);
      setIsPopUpOpen({
        [popUp]: type === 'open',
      });
    };

  // Currently the edit zone functionality is inside the accoridion. This is to handle form in accordion.
  const handleZoneFormSubmit = (zone: Zone) => {
    if (isEditForm) {
      return setZones((prev) => prev.map(function (zn) {
        return this.zone_id === zn.zone_id ? this : zn;
      }, {...zone})
      );
    }

    return setZones((prev) => [...prev, zone]);
  };

  const handleFormSubmit =
    (formType: PopUpFormTypes, callback?: any) => (failed: boolean, error: string, data?: unknown) => {
      if (failed) {
        setErrorMessage(error);
        setIsErrorPopUpOpen(true);
        return;
      }

      if (callback) {
        callback(data);
      }

      handlePopUp(formType, 'close', false)();
    };
  const columns = [
    {
      field: 'location_code',
      label: 'Location Code',
      width: 120,
    },
    {
      field: 'location_name',
      label: 'Location Name',
      width: 120,
    },
    {
      field: 'notes',
      label: 'Notes',
      width: 200,
      valueGetter: (row: any) => (
        <LocationNotes notes={row.notes} switchChecked={row.switchChecked} />
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      location_code: 'LOC001',
      location_name: 'Receiving Area',
      notes:
        'The warehouse consists of locations, such as bins, spaces on a rack, pallet spaces on the floor.',
      switchChecked: false,
    },
    {
      id: 2,
      location_code: 'LOC002',
      location_name: 'Storage Area',
      notes:
        'Reserved for storing products before they are moved to other areas.',
      switchChecked: false,
    },
    {
      id: 3,
      location_code: 'LOC003',
      location_name: 'Shipping Area',
      notes: 'Designated area for packing and shipping orders.',
      switchChecked: false,
    },
  ];

  const LocationNotes: React.FC<{ notes: string; switchChecked: boolean }> = ({
    notes,
    switchChecked,
  }) => (
    <Box display='flex' alignItems='center'>
      <Typography style={{ fontWeight: 'normal', fontSize: '12px', flex: 1 }}>
        {notes}
      </Typography>
      <Box display='flex' alignItems='center' marginLeft='auto'>
        <IOSSwitch
          defaultChecked={switchChecked}
          size='small'
          sx={{ transform: 'scale(0.75)' }}
        />
        <Button
          variant='text'
          style={{ fontWeight: 'normal', fontSize: '12px' }}
          endIcon={<EditPenIcon />}
        />
      </Box>
    </Box>
  );

  const handleZoneUpdate = async ({warehouse_id, zone_id, is_active}: Partial<Zone>) => {
    const { failed } = await updateZone({warehouse_id, zone_id, is_active});

    if (failed) {
      return setErrorMessage(`Failed to ${ is_active? 'enable' : 'disable' } the zone. Please try again`);
    }

    setZones((zones) => {
      return zones.map(function zoneMapper(zone) {
        const isActive = this.zone_id === zone.zone_id ? this.is_active: zone.is_active;
        return {...zone, is_active: isActive};
      }, { zone_id, is_active });
    });
  };

  const onClickConfirm = (type: ConfirmationDialogType, data: Partial<Zone & Location>) => async () => {
    const { warehouse_id, zone_id, is_active } = data;

    switch(type) {
    case 'zone':
      await handleZoneUpdate({ warehouse_id, zone_id, is_active });
      break;
    }

    onClickCancel();
  };

  const onClickCancel = () => setPopUpDialogConfig({...defaultDialogConfig});

  const handleZoneToggle = ({ warehouse_id, zone_id, has_active_locations }: Partial<Zone>) => async (enable: boolean) => {
    if (!enable && has_active_locations) {
      return setPopUpDialogConfig({
        type: 'zone',
        message: 'There are active locations in this zone. If disabled, you cannot move the pallets in this zone. Do you still want to disable?',
        isOpen: true,
        data: {
          warehouse_id,
          zone_id,
          is_active: enable,
          has_active_locations
        }
      });
    }

    await handleZoneUpdate({ warehouse_id, zone_id, is_active: enable });
  };

  const AccordionSection: React.FC<{
    zones: Zone[];
    rows: any[];
    columns: any[];
  }> = ({ zones, rows, columns }) => (
    <Box
      sx={{
        maxHeight: '300px',
        overflowY: 'auto', 
      }}
    >
      {zones.map(({zone_id, zone_code, zone_name, notes, is_active, warehouse_id, has_active_locations}) => (
        <CustomAccordion key={zone_id} title={`Zone-${zone_code}`} switchChecked={is_active} onEditClick={() => {
          setSelectedZone({ zone_id, zone_code, zone_name, notes, warehouse_id});
          handlePopUp('zone', 'open', true)();
        }} onSwitchChange={handleZoneToggle({warehouse_id, zone_id, has_active_locations})}>
          <BodyText>{zone_name}</BodyText>
          <BodyText>{notes}</BodyText>
          <Box
            style={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}
          >
            <TitleText>Location</TitleText>
            <Button
              variant='contained'
              startIcon={<LocationIcon />}
              style={{ height: '40px', marginLeft: 'auto' }}
            >
              Add Location
            </Button>
          </Box>
          <Box className='location-table-container' sx={{ marginTop: '16px' }}>
            <Table rows={rows} columns={columns} infiniteScroll />
          </Box>
        </CustomAccordion>
      ))}
    </Box>
  );

  return (
    <>
      <WarningCard
        open={isErrorPopUpOpen}
        onClose={() => setIsErrorPopUpOpen(false)}
        message={errorMessage}
        severity='error'
        durationInSeconds={5}
      />
      <Stack spacing={5} className='settings-container'>
        <WarehouseTab>
          <Button
            variant='contained'
            color='primary'
            startIcon={<WarehouseIcon />}
            className='height-40px'
            onClick={handlePopUp('warehouse', 'open', false)}
          >
            Add Warehouse
          </Button>
        </WarehouseTab>
        {isDataFetched && selectedWarehouse?.warehouse_id && (
          <Stack spacing={5}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <HeaderWithAction
                headerFontSize='32px'
                showIcon={true}
                content={`Warehouse-${selectedWarehouse.warehouse_code}`}
                onIconClick={handlePopUp('warehouse', 'open', true)}
              />
              <Button
                variant='contained'
                color='primary'
                startIcon={<ZoneIcon />}
                className='height-40px'
                onClick={handlePopUp('zone', 'open', false)}
              >
                Add Zone
              </Button>
            </Stack>
          </Stack>
        )}
        {isDataFetched && !selectedWarehouse?.warehouse_id && (
          <p>No Warehouse data found</p>
        )}
        {isDataFetched && selectedWarehouse?.warehouse_id && (
          <p>{selectedWarehouse.warehouse_name}</p>
        )}
        <PopUpDialog
          open={Boolean(isPopUpOpen[selectedForm])}
          onClose={handlePopUp(selectedForm, 'close')}
          title={`${isEditForm ? 'Edit' : 'Create'} ${selectedForm}`}
        >
          { isPopUpOpen.warehouse && <WarehouseForm
            value={
              isEditForm ? selectedWarehouse : ({} as unknown as Warehouse)
            }
            onSubmit={handleFormSubmit('warehouse')}
            onClose={handlePopUp('warehouse', 'close')}
            edit={isEditForm}
          /> }
          { isPopUpOpen.zone && <ZoneForm
            value={
              isEditForm ? selectedZone : ({warehouse_id: selectedWarehouse.warehouse_id} as unknown as Zone)
            }
            onSubmit={handleFormSubmit('zone', handleZoneFormSubmit)}
            onClose={handlePopUp('zone', 'close')}
            edit={isEditForm}
          /> }
        </PopUpDialog>
        { isZoneDataFetched && zones.length > 0 && <AccordionSection
          zones={zones}
          rows={rows}
          columns={columns}
        /> }
        { isZoneDataFetched && zones.length === 0 && <p>No Zone data found</p> }
      </Stack>
      <PopUpDialog
        open={popUpDialogConfig.isOpen}
        onClose={onClickCancel}
        title='Confirm !'
      >
        <>
          <Content>
            <Typography fontWeight='500'>{popUpDialogConfig.message}</Typography>
          </Content>
          <Actions className='btn-container flex-justify-end'>
            <Button onClick={onClickConfirm(popUpDialogConfig.type, popUpDialogConfig.data)}>Yes</Button>
            <Button
              variant='text'
              color='secondary'
              className='cancel-btn'
              onClick={onClickCancel}
            >
              Discard
            </Button>
          </Actions>
        </>
      </PopUpDialog>
    </>
  );
};

export default Warehouses;
