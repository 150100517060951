import moment from 'moment';

interface FormatNumberOptions extends Intl.NumberFormatOptions {
  locals?: string | string[];
}

interface FormatDateOptions extends Omit<Intl.DateTimeFormat, 'format' | 'resolvedOptions' | 'formatToParts' | 'formatRange' | 'formatRangeToParts'> {
  locals?: string | string[];
  format?: Intl.DateTimeFormat['format'];
  resolvedOptions?: Intl.DateTimeFormat['resolvedOptions'];
  formatToParts?: Intl.DateTimeFormat['formatToParts'];
  formatRange?: Intl.DateTimeFormat['formatRange'];
  formatRangeToParts?: Intl.DateTimeFormat['formatRangeToParts'];
}

export const formatNumberToCurrencyAmount = (value: number, options: FormatNumberOptions = {}) => {
  if (!value) {
    value = 0;
  }

  const { locals, ...rest } = options;
  return new Intl.NumberFormat(locals || 'en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    ...rest,
  }).format(value);
};

export const formatTimestampToDate = (value: Date | number, options: FormatDateOptions = {}) => {
  if (!value) {
    return '';
  }
  value = new Date(value);
  const { locals, ...rest } = options;
  const formattedDate = new Intl.DateTimeFormat(locals || 'en-IN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...rest,
  }).format(value).split(' ');
  return `${formattedDate[0]} ${formattedDate[1]}, ${formattedDate[2]}`;
};

const currencyFormatter = Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR'
});

const currencyFormat = (value: number) => {
  if (!value) {
    return value;
  }

  return currencyFormatter.format(value).replace(/₹/, '₹ ');
};

const dateTimeFormat = (value: Date | string | number, format: 'date' | 'time' | 'datetime' | 'timestamp' | 'yyyy-MM-DD') => {
  if (!value) {
    return value;
  }

  if (format === 'date') {
    return moment(value).format('MMM DD, yyyy');
  }

  if (format === 'datetime') {
    return moment(value).format('MMM DD, yyyy hh:mm A');
  }

  if (format === 'time') {
    return moment(value).format('hh:mm A');
  }

  if (format === 'timestamp') {
    return moment(value).format('DD-MM-YYYY HH:mm:ss'); 
  }

  if (format === 'yyyy-MM-DD') {
    return moment(value).format(format);
  }
};

const getTodayDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); 
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};

const getAvatarText = (value: string) => value.match(/^\s*(\w)|\b(\w)(?=\S+$)/g)?.join('');

export { currencyFormat, dateTimeFormat, getAvatarText, getTodayDate };

export const downloadBase64String = (string, filename = 'download') => {
  const link = document.createElement('a');
  link.href = string;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const removeEmptyValues = (obj) => {
  if (!obj) {
    return undefined;
  }
  return Object.fromEntries(
    Object.entries(obj).filter(([value]) => value !== null && value !== '')
  );
};
