import React, { useEffect, useRef, useState } from 'react';
import { ContentWrapper } from 'views/components/styled';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useWarehouseStore } from 'store/warehouse';
import { Tab, Stack, Typography, Box, Divider, InputAdornment } from '@mui/material';
import { Actions, CustomTabs, LineWrapper } from './StyledComponent';
import ConfirmDialog from 'views/components/elements/ConfirmDialog';
import PdfViewer from 'views/components/elements/PdfViewer';
import LogHistory from './log-history';
import { Button, Column, Input, Table, Form } from 'views/components/elements';
import './review-delivery.scss';
import './delivery-list.scss';
import { DeliveryLine } from 'models';
import { Search as SearchIcon, Replay as RefreshIcon } from '@mui/icons-material';
import DropDownFilter from 'views/components/elements/DropDownFilter';
import ImageModal from 'views/components/elements/ImageModal';
import WarningCard from 'views/components/elements/WarningCard';
import { DELIVERY_STATUS } from 'views/shared/utils/delivery-utils';

const lineItemStatus = ['printed', 'shipped', 'delivered'];

enum ReviewDeliveryActionButtons {
  back='back',
  edit='edit',
  updateVehicle='updateVehicle',
  print='print',
  reprint='reprint',
  lr='lr',
  logHistory='logHistory',
  tabChange='tabChange',
}

export default function ReviewDelivery() {
  const navigate = useNavigate();
  const { delivery_id } = useParams();
  const { state: locationState } = useLocation();

  const [pdfData, setPdfData] = useState('');
  const [pdfTitle, setPdfTitle] = useState('');
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isPrintWindowOpen, setIsPrintWindowOpen] = useState(false);
  const [isLogHistoryOpen, setIsLogHistoryOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [query, setQuery] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    delivery,
    fetchDelivery,
    resetData,
    fetchShippingLabel,
    fetchLRReceipt,
    updateDeliveryStatus,
  } = useWarehouseStore();

  const isInitialFetchCompleted = useRef(false);

  useEffect(() => {
    if (!isInitialFetchCompleted.current) {
      fetchDelivery(delivery_id);
      isInitialFetchCompleted.current = true;
    }

    return () => resetData();
  }, []);

  const handlePrintWindowOpen = () => setIsPrintWindowOpen(true);
  const handlePrintWindowClose = () => setIsPrintWindowOpen(false);

  const handleConfirmDialogOpen = () => setIsConfirmDialogOpen(true);
  const handleConfirmDialogClose = () => setIsConfirmDialogOpen(false);

  const handleLogHistoryOpen = () => setIsLogHistoryOpen(true);
  const handleLogHistoryClose = () => setIsLogHistoryOpen(false);

  const [isResetDisabled, setIsResetDisabled] = useState(true);

  const handleBtnClick = (type: string) => () => {
    const redirectToDeliveryListPage = locationState?.redirectToDeliveryListPage ?? '/delivery';

    switch (type) {
    case 'edit':
      navigate(`/delivery/${delivery_id}`, {
        state: {
          redirectToViewPage: `/delivery/${delivery_id}/review`,
          redirectToDeliveryListPage
        },
      });
      break;
    case 'back':
      navigate(redirectToDeliveryListPage);
      break;
    case 'tabChange':
      navigate(redirectToDeliveryListPage);
      break;
    }
  };

  const handlePrint = async () => {
    setIsConfirmDialogOpen(false);
    if (isReviewPending) {
      const { failed, error } = await updateDeliveryStatus(
        delivery_id,
        'printed'
      );
      if (failed) {
        return handleError(error);
      }
      fetchDelivery(delivery_id);
    }
    const { failed, data, error } = await fetchShippingLabel(delivery_id);
    if (failed) {
      return handleError(error);
    }
    handlePrintWindowOpen();
    setPdfTitle('Shipping label');
    setPdfData(data);
  };

  const handleLRPrint = async () => {
    if (!(delivery?.vehicle?.number && delivery?.vehicle_operator?.name)) {
      return handleError('Please update vehicle and driver details before proceeding.');
    }

    const { failed, error, data } = await fetchLRReceipt(delivery_id);
    if (failed) {
      return handleError(error);
    }
    handlePrintWindowOpen();
    const consignment_number = `${(delivery?.consignment_number || 'Copy')}`;
    setPdfTitle(`Lr_${consignment_number.substring(0,10)}`);
    setPdfData(data);
  };

  const isReviewPending = !['printed', 'shipped', 'delivered'].find((status) => status === delivery?.status);
  const showLogHistory = Boolean(['received', 'printed', 'shipped', 'delivered'].find((status) => status === delivery?.status)) && delivery.delivery_id;
  const isShippedOrDelivered = Boolean(['shipped', 'delivered'].find((status) => status === delivery?.status));
  const isReceivedOrPrinted = Boolean(['received', 'printed'].find((status) => status === delivery?.status));
  const isCasesTab = Boolean(
    ['printed', 'shipped', 'delivered'].find(
      status => status === delivery?.status
    )
  );

  const filteredDeliveries = delivery.lines.filter(line =>
    query.length === 0 || line.case_number.toString() === query
  ).filter(
    line => (
      statusFilter.length === 0 ||
      statusFilter.includes(line.status)
    )
  );
  const getLabel = (status, count) => {
    const statusClass = `status-count status-${status.toLowerCase()}`;
    return (
      <>
        {`${status.toUpperCase()}: `}
        <span className={statusClass}>{count || 0}</span>
      </>
    );
  };
  const columns = [
    {
      field: 'case_number',
      label: 'Case',
      width: 120,
    },
    {
      field: 'status',
      label: 'Status',
      width: 'auto',
      valueGetter: row => (
        <span className={`status ${row.status.toLowerCase()}`}>
          {row.status}
        </span>
      ),
    },
  ] as Column<DeliveryLine>[];

  const numberOfItemsShipped = (delivery?.status === 'printed' && delivery?.lines?.filter(({ status }) => status === 'shipped').length) || 0;

  const ImagePreview = ({ src, alt, message }) => (
    <Box className='proof-of-delivery-container height-45vh'>
      {src ? (
        <ImageModal src={src} alt={alt} className='delivery-proof-image' />
      ) : (
        <Box sx={{ ml: 3 }}>{message}</Box>
      )}
    </Box>
  );
  const StatusLabelGroup = ({ statuses, getLabel }) => (
    <Box display='flex' alignItems='center' mt={2}>
      {statuses.map(({ key, label, value }, i) => (
        <React.Fragment key={key}>
          <Box display='flex' alignItems='center'>
            <Typography variant='subtitle2' sx={{ mr: 1 }}>
              {getLabel(label, value)}
            </Typography>
            {i < statuses.length - 1 && (
              <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
            )}
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );  
  const handleTabChange = newValue => {
    setSelectedTab(newValue);
  };

  const handleSubmit = ({ query }) => {
    setQuery(query);
    setIsResetDisabled(false);
  };

  const handleReset = () => {
    setQuery('');
    setIsResetDisabled(true);
  };

  const renderTabs = () => {
    const tabs = [];
    if (['printed', 'shipped', 'delivered'].includes(delivery?.status)) {
      tabs.push(
        { label: 'Cases' },
        { label: 'POD' },
        { label: 'Consignee POD' }
      );
    }

    return (
      <CustomTabs
        value={selectedTab}
        onChange={(_, newValue) => handleTabChange(newValue)}
        aria-label='delivery tabs'
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} sx={{ textTransform: 'none' }} />
        ))}
      </CustomTabs>
    );
  };

  const handleStatusFilter = (selectedItems: string[]) => setStatusFilter([...selectedItems]);

  useEffect(() => {
    if (delivery.lines.length) {
      const lineStatuses: string[] = [];
      for (const status of delivery.lines.map(({ status }) => status)) {
        if (!lineStatuses.find((s) => s === status)) {
          lineStatuses.push(status);
        }
      }

      setStatusFilter([...lineStatuses]);
    }

  }, [delivery]);

  const handleError = (error: string) => {
    setErrorMessage(error);
    setIsErrorPopUpOpen(true);
  };

  const getButtons = (btnTypes: ReviewDeliveryActionButtons[]) => {
    const btns = [];

    for (const btnType of btnTypes) {
      const isPrint = btnType === ReviewDeliveryActionButtons.print;
      switch (btnType) {
      case ReviewDeliveryActionButtons.back:
      case ReviewDeliveryActionButtons.edit:
      case ReviewDeliveryActionButtons.updateVehicle:
        btns.push(
          <Button
            className='cancel-btn'
            variant='text'
            color='secondary'
            onClick={handleBtnClick(btnType === ReviewDeliveryActionButtons.updateVehicle ? 'edit' : btnType)}
            key={btnType}
          >
            {btnType === 'back' ? 'Back' : (btnType === 'edit') ? 'Edit' : 'Update Vehicle'}
          </Button>
        );
        break;
      case ReviewDeliveryActionButtons.print:
      case ReviewDeliveryActionButtons.reprint:
        btns.push(
          <Button
            onClick={isPrint ? handleConfirmDialogOpen : handlePrint}
            key={btnType}
          >
            {isPrint ? 'Confirm & Print' : 'Reprint'}
          </Button>
        );
        break;
      case ReviewDeliveryActionButtons.lr:
        btns.push(
          <Button onClick={handleLRPrint} key={btnType}>
            Print LR
          </Button>
        );
        break;
      case ReviewDeliveryActionButtons.logHistory:
        btns.push(
          <Button onClick={handleLogHistoryOpen} key={btnType}>
            Log History
          </Button>
        );
        break;
      }
    }

    return btns;
  };

  const ButtonElements = (() => {
    if (!delivery.status) {
      return;
    }
    if (isReviewPending) {
      return getButtons([
        ReviewDeliveryActionButtons.back,
        ReviewDeliveryActionButtons.edit,
        ReviewDeliveryActionButtons.logHistory,
        ReviewDeliveryActionButtons.print,
      ]);
    }

    if (isShippedOrDelivered) {
      return getButtons([
        ReviewDeliveryActionButtons.back,
        delivery?.status === DELIVERY_STATUS[DELIVERY_STATUS.shipped]
          ? ReviewDeliveryActionButtons.updateVehicle
          : undefined,
        ReviewDeliveryActionButtons.logHistory,
      ]);
    }

    return getButtons([
      ReviewDeliveryActionButtons.back,
      ReviewDeliveryActionButtons.updateVehicle,
      ReviewDeliveryActionButtons.logHistory,
      ReviewDeliveryActionButtons.reprint,
      ReviewDeliveryActionButtons.lr,
    ]);
  })();

  const formatVehicleDetails = vehicle => {
    const details = [
      vehicle?.number,
      vehicle?.name,
      vehicle?.vehicle_tonnage,
    ].filter(Boolean);
    return details.length ? details.join(' - ') : '-';
  };

  const formatTransporterDetails = transporter => {
    const details = [transporter?.name, transporter?.phone].filter(Boolean);
    return details.length ? details.join(' - ') : '-';
  };

  const formatDriverDetails = operator => {
    const details = [operator?.name, operator?.phone || operator?.email].filter(
      Boolean
    );
    return details.length ? details.join(' - ') : '-';
  };

  return (
    <ContentWrapper style={{ height: 'auto' }}>
      <WarningCard
        open={isErrorPopUpOpen}
        onClose={() => setIsErrorPopUpOpen(false)}
        message={errorMessage}
        severity='error'
      />
      <h2>{isReviewPending ? 'Review' : 'View'} Delivery</h2>
      <Stack direction='row' spacing={2} className='view-page-holder'>
        <Box className='left-side-container' flex={1}>
          <Box className='view-details-container height-45vh'>
            {isReceivedOrPrinted && (
              <Box className='review-container margin-top-24px'>
                <img
                  alt='warehouse-logo'
                  className='warehouse-logo'
                  src='/excel-logo.png'
                />
                <Box className='delivery'>
                  <span className='delivery-name'>
                    {delivery?.customer?.name}
                  </span>
                  <span className='delivery-address'>
                    {delivery?.delivery_location ??
                      delivery?.customer?.shipping_address}
                  </span>
                  <span className='delivery-invoice'>
                    <LineWrapper>
                      <Typography variant='inherit'>
                        {`Invoice No: ${delivery?.invoice_number}`}
                      </Typography>
                    </LineWrapper>
                  </span>
                </Box>
                <img
                  alt='qr-code'
                  className='delivery-qr_code'
                  src='/dummy-qr.png'
                />
                <span
                  className='delivery-total_cases'
                  title='total cases shipped / total no. of cases'
                >
                  {numberOfItemsShipped}/{delivery?.total_cases}
                </span>
              </Box>
            )}
            {isShippedOrDelivered && (
              <Box className='details-container'>
                <Box className='misc margin-top-24px'>
                  <h4>Delivery Details</h4>
                  <Box className='misc-container margin-top-24px'>
                    <span className='key'>Name</span>
                    <span className='value'>{delivery?.customer?.name}</span>
                    <span className='key'>Location</span>
                    <span className='value'>
                      {delivery?.delivery_location ||
                        delivery?.customer?.shipping_address}
                    </span>
                    <span className='key'>Invoice No</span>
                    <span className='value'>
                      <LineWrapper>
                        <Typography variant='inherit'>
                          {`${delivery?.invoice_number}`}
                        </Typography>
                      </LineWrapper>
                    </span>
                    <span className='key'>No. of Cases</span>
                    <span className='value'>{delivery?.total_cases}</span>
                  </Box>
                </Box>
              </Box>
            )}
            {showLogHistory && (
              <Box className='misc margin-top-24px'>
                <h4>Other Information</h4>
                <Box className='misc-container margin-top-24px'>
                  <span className='key'>Vehicle</span>
                  <span className='value'>
                    {formatVehicleDetails(delivery?.vehicle)}
                  </span>
                  <span className='key'>Transporter</span>
                  <span className='value'>
                    {formatTransporterDetails(delivery?.vehicle?.transporter)}
                  </span>
                  <span className='key'>Driver</span>
                  <span className='value'>
                    {formatDriverDetails(delivery?.vehicle_operator)}
                  </span>
                  <span className='key'>Notes</span>
                  <span className='value'>
                    <LineWrapper>
                      <Typography variant='inherit'>
                        {delivery?.notes || '-'}
                      </Typography>
                    </LineWrapper>
                  </span>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {delivery?.status !== 'received' && (
          <Divider
            orientation='vertical'
            flexItem
            style={{ margin: '0 16px' }}
          />
        )}
        <Box className='right-side-container' flex={1}>
          {renderTabs()}
          {selectedTab === 0 && isCasesTab && (
            <Box className='cases-list-container'>
              <Box className='row action'>
                <Stack direction='row' spacing={2} height='40px'>
                  <Form
                    initialValues={{ query }}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    <Stack direction='row' spacing={2} alignItems='center'>
                      <Input
                        name='query'
                        placeholder='Search Case'
                        startAdornment={
                          <InputAdornment position='start'>
                            <SearchIcon sx={{ color: '#BDBDBD' }}/>
                          </InputAdornment>
                        }
                        sx={{
                          width: '180px',
                          height: '40px'
                        }}
                      />
                      <DropDownFilter
                        onChange={handleStatusFilter}
                        selectedFields={statusFilter}
                        inputFields={lineItemStatus}
                      />
                      <Button
                        variant='text'
                        startIcon={
                          <span id='package-icon'>
                            <SearchIcon />
                          </span>
                        }
                        type='submit'
                      >
                        Search
                      </Button>
                      <Button
                        variant='text'
                        startIcon={
                          <span id='package-icon'>
                            <RefreshIcon />
                          </span>
                        }
                        onClick={handleReset}
                        disabled={isResetDisabled}
                        aria-disabled={isResetDisabled}
                      >
                        Reset
                      </Button>
                    </Stack>
                  </Form>
                </Stack>
              </Box>
              <Box className='line-status-container height-45vh'>
                <StatusLabelGroup
                  statuses={['printed', 'shipped', 'delivered'].map(key => ({
                    key,
                    label: key,
                    value: delivery?.line_status?.[key],
                  }))}
                  getLabel={getLabel}
                />
                <Table
                  rows={filteredDeliveries}
                  columns={columns}
                  stickyHeader
                  infiniteScroll
                />
              </Box>
            </Box>
          )}
          {selectedTab === 1 && (
            <ImagePreview
              src={delivery.pod_image_link}
              alt='Proof of Delivery'
              message='Pending POD'
            />
          )}
          {selectedTab === 2 && (
            <ImagePreview
              src={delivery.lr_image_link}
              alt='Consignee Proof of Delivery'
              message='Pending Consignee POD'
            />
          )}
        </Box>
      </Stack>
      <Actions className='btn-container'>{ButtonElements}</Actions>
      {isReviewPending && delivery.delivery_id && (
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          confirmationTitle='Continue Printing!!!'
          onClickConfirm={handlePrint}
          onClose={handleConfirmDialogClose}
          confirmationMsg='Please click confirm to proceed with the printing.'
        />
      )}
      {showLogHistory && (
        <>
          <LogHistory
            isOpen={isLogHistoryOpen}
            onClose={handleLogHistoryClose}
          />
        </>
      )}
      {pdfData && (
        <PdfViewer
          pdfTitle={pdfTitle}
          pdfData={pdfData}
          open={isPrintWindowOpen}
          handleClose={handlePrintWindowClose}
        />
      )}
    </ContentWrapper>
  );
}
